import { createRoot } from "react-dom/client";

import { LazyLoadComponent } from "./widgets/lazy_loader";
import { Spin } from "antd";
import "./index.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <LazyLoadComponent
    loader={
      <div className="center">
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        />
      </div>
    }
    component={() => import("./app.jsx")}
    componentName="App"
  />
);
